/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages 
    'common': {
      init: function() {
        function checkInView(elem,cont,partial)
        {
            var container = $(cont);
            var contHeight = container.height();
            var contTop = container.scrollTop();
            var contBottom = contTop + contHeight ;
        
            var elemTop = $(elem).offset().top - container.offset().top;
            var elemBottom = elemTop + $(elem).height();
        
            var isTotal = (elemTop >= 0 && elemBottom <=contHeight);
            var isPart = ((elemTop < 0 && elemBottom > 0 ) || (elemTop > 0 && elemTop <= container.height())) && partial ;
        
            if( isTotal  || isPart ){
              return true;
            }else{
              return false;
            }
        }
        // JavaScript to be fired on all pages
        $( "#repeatpass" ).on("keyup",function() {
          var pass = $("#enterpass").val();
          var rep = $("#repeatpass").val();
          if(pass === rep){
            $("#repeatpass + .message").html('Good').addClass('success').removeClass('fail');
          } else{
            $("#repeatpass + .message").html('Mismatch').addClass('fail').removeClass('success');
          }
        });
        $("#opc_input").focus(function(){
          $("#opc_button").show();
        });
        //$(".loading-overlay").on("click",function(e){
        //  if (e.target === this){
        //    $(".loading-overlay").fadeOut(200,function(){
        //      $(this).find('.cont').html('<i class="fa fa-spin fa-spinner"></i>');  
        //    });
        //  }
        //});
        
        $('#applic h3 a').on('click',function(e){
          e.preventDefault();
          var tar = $(this).data('target');
          $(tar).toggle();
        });
        $(".loading-overlay .cont").on("click",function(e){
          e.preventDefault();
        });
        $(document).on("click",'.closecard',function(e){
          e.preventDefault();
          console.log('clisked');
          $(this).parents('.loading-overlay').fadeOut(200,function(){
            $(this).find('.cont').html('<i class="fa fa-spin fa-spinner"></i>');  
          });
          return false;
        });
        $(document).on("click",".entermanually",function(){ 
          $(this).parents('.card').find('.postcode-finder').hide();
          $(this).parents('.card').find(".addFound").fadeIn(); 
          $(this).parents('.card').find("#addressfound").fadeIn();
          $(this).parents('.fieldwrap').hide();
        });
        $('.acf-true-false').each(function(){
          $(this).find('input[type=checkbox]').after('<span></span>');
        });
        $('#peopleTab, .page-template-template-agreements .newVis').on('mouseover','.cradleCell',function(event){
          if( event.target === this || $(event.target).hasClass('fa') ){
            $(this).find('.tooltip').addClass('show');
          }
        }).on('mouseleave','.cradleCell',function(event){
          console.log(event);
          $(this).find('.tooltip').removeClass('show');
        });
        $('.page-space').on('click','.deleteUser',function(){
          var id = $(this).data('id');
          $('#ajax').fadeIn();
          $.ajax({
            type:"POST",
            url: "/wp-admin/admin-ajax.php",
            data:{action:"deleteUser",id:id,security:non},
            success:function(data){
              
                var sel = '.person[data-id='+id+']';
                $(sel).fadeOut(300,function(){$(sel).remove();});
                qMessage(data,'success',false);
                console.log(data);
                location.reload();
              
            }
          }); 
        });
        $('.page-space').on('click','.deleteProperty',function(){
          var id = $(this).data('id');
          $('#ajax').fadeIn();
          $.ajax({
            type:"POST",
            url: "/wp-admin/admin-ajax.php",
            data:{action:"deleteProperty",id:id,security:non},
            success:function(data){
              
                var sel = '.propSummary[data-id='+id+']';
                $(sel).fadeOut(300,function(){$(sel).remove();});
                qMessage(data,'success',false);
                console.log(data);
                location.reload();
              
            }
          }); 
        });
        $('#doLetReport').click(function(){
          var t = $(this);
          var start = $('#start_date').val();
          var end = $('#end_date').val();
          start = start.replace(/\//g,'-');
          end = end.replace(/\//g,'-');
          var type = $('#report_type').val();
          var url = '/print/?doReport=letReport&type='+type+'&start='+start+'&end='+end;
          if(start.length !== 10 || end.length !== 10 ){
            console.log(url);
            t.siblings('.error').html('Please choose all the options');
            return;
          }
          
          window.location.replace(url);
        });
      }, 
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $('body').on('change','.acf-date-picker .input',function(){
          console.log('doingit');
          var val = $(this).val();
          $(this).siblings('.input-alt').val(val);
        });
        $('body').on('click','.dpick .acf-input-wrap',function(e){
          if (e.target !== this) {
            return;
          }
          $(this).find('input').focus();
        });
        $('.collectBy select').change(function(){
          var val = $(this).val();
          var form = $(this).parents('form');
          if($(this).val() === 'other'){  
            form.find('.otherNamedWrap').show();
          }else{
            form.find('.otherNamedWrap').hide();
          }
          if(val !== 'tenant' && val !== 'guarantor' && val !== 'other'){
            form.find('.collectTime').hide();
          }else{
            form.find('.collectTime').show();
          }
        });
        $('body').on('click','.redoKeySign',function(){
          var t=$(this);
          var id = t.data('id');
          var dat = {
            security:non,action:'redoKeySign',id:id
          };
          $.ajax({
            type:"POST",
            url: "/wp-admin/admin-ajax.php",
            data: dat,
            success:function(data){
              t.parents('td').html('<i class="fa fa-spinner fa-spin"></i> Pending signature');
              var dat = {
                security:non,action:'checkKeyStatus',id:id
              };
              var interval = setInterval(function(){
                $.ajax({
                  type:"POST",
                  url: "/wp-admin/admin-ajax.php",
                  data: dat,
                  success:function(data){
                    console.log(data);
                    var d = JSON.parse(data);
                    if(d.res === 'collected'){
                      var sel = '.app-'+id;
                      $(sel).html(d.out);
                      $('.fancybox').fancybox();
                      clearInterval(interval);
                    }
                  }
                });
              },2000);
            }
          });
        });
        $('body').on('click','.approveKeys',function(){
          var t=$(this);
          var id = t.data('id');
          var dat = {
            security:non,action:'approveKeys',id:id
          };
          $.ajax({
            type:"POST",
            url: "/wp-admin/admin-ajax.php",
            data: dat,
            success:function(data){
              var sel = '.app-'+id;
              $(sel).html(data);
            }
          });
        });
        $('body').on('submit','.keyCollectionShedule',function(e){
          e.preventDefault();
          var t = $(this);
          var dat = $(this).serialize();
          var rel = $(this).data('rel');
          t.find('.olay').fadeIn();
          $.ajax({
            type:"POST",
            url: "/wp-admin/admin-ajax.php",
            data: dat,
            success:function(data){
              console.log(data);
              var d = JSON.parse(data);
              if(d.res === 'success'){
                $(rel).html(d.out);
                $.fancybox.close();
                if( $(rel).find('.fancybox').length ){
                  $(rel).find('.fancybox').fancybox();  
                }
                qMessage('Success','success',false);
              }else if(d.res === 'invalid'){
                qMessage('Errors, check the form','fail',false);
                $('body').append(d.out);
                t.find('.olay').hide();
              }else{
                qMessage('Error, please try again','fail',false);
                //location.reload();
                t.find('.olay').hide();
              }
            }
          });


        });
        $('body').on('click','.sendKeyCollectionEmail',function(e){
          e.preventDefault();
          var t = $(this);
          var id = t.data('id');
          t.hide();
          t.siblings('i').remove();
          t.after('<i class="fa fa-spin fa-spinner"></i>');

          $(t).siblings('.loading').show();
          var data = {
            security:non,
            action:'sendKeyCollectionEmail',
            id:id
          };
          $.ajax({
            type:"POST",
            url: "/wp-admin/admin-ajax.php",
            data: data,
            success:function(data){
              
              if(data === 'success'){

                var dat = {
                  security:non,action:'checkKeyStatus',id:id
                };
                console.log(dat);
                var interval = setInterval(function(){
                  $.ajax({
                    type:"POST",
                    url: "/wp-admin/admin-ajax.php",
                    data: dat,
                    success:function(data){
                      console.log(data);
                      var d = JSON.parse(data);
                      if(d.res === 'collected'){
                        var sel = '.app-'+id;
                        $(sel).html(d.out);
                        $('.fancybox').fancybox();                        
                        clearInterval(interval);
                      }
                    }
                  });
                },2000);
                  
              }else{
                qMessage('Error, please try again','fail',false);
                console.log(data);
                t.show();
                $(t).siblings('.loading').hide();
              }
            }
          });
           

        });
        window.qMessage = function quickSuccess(message,type,required){
          var icon="";
          if(type==="success"){
            icon = 'check';
          } else if(type==="fail"){
            icon = 'close';
          } else if(type === "warn"){
            icon = "warning";
          }
          var btn = "";
          if(required === true){
            btn = "<br/><button class='btn-small closemess'>Ok, got it</button>";
          }
          var html = '<div class="quicksuccess"><i class="fa fa-'+icon+'"></i><span>'+message+btn+'</span></div>';
          $('body').append(html);
          if(required === false){
            setTimeout(function(){
              $('.quicksuccess').fadeOut(1500,function(){$('.quicksuccess').remove();});
            },1500);
          }
        };
        $(document).ready(function(){
          $('.fancybox, .acf-field-5c5c682243778 .file-info a, .fileButton .file-info a').fancybox();

        });
        $('.deleteNotification').click(function(){
          var t = $(this);
          var id = $(this).parents('.notification').data('id');
          var data = {
            security:non,
            action:'deleteNotification',
            id:id
          };
          $.ajax({
            type:"POST",
            url: "/wp-admin/admin-ajax.php",
            data: data,
            success:function(data){
              if(data === 'success'){
                qMessage('Deleted','success',false);
                t.parents('.notification').remove();
              }else{
                qMessage('Failed, try again','fail',false);
              }
            }
          }); 
        });
        function setupAddress(){
          $('#postcode_lookup').getAddress({
              api_key: '508gluHE5kCEAWhPuT78dA6002',  
              output_fields:{
                  line_1: '#address_1',
                  line_2: '#address_2',
                  line_3: '#address_3',
                  post_town: '#city',
                  county: '#county',
                  postcode: '#postcode'
              },
              button_label: "Find",
              button_disabled_message: "<i class='fa fa-spin fa-spinner'></i>",
              onLookupSuccess: function(data){
                $("#opc_button").hide();
                $("<div id='postcodedd' class='selectbg'></div>").appendTo("#postcode_lookup");
                $("#opc_dropdown").appendTo('#postcodedd');
              },
              onAddressSelected: function(elem,index){
                $("#addressfound").fadeIn();
              }
          });
        }
        setupAddress();
        window.setDates = function setupDates(){
          $( ".datepicker" ).datepicker({
            autoclose:true,
            format: 'dd/mm/yyyy',
            forceParse:false,
            orientation:'bottom'
          });
          $('.acf-field.dpick .acf-input').each(function(){
            if($(this).find('.labl').length === 0){
              var id = $(this).find('input').attr('id');
              $(this).prepend('<span class="labl" data-for="#'+id+'"></span><a class="clear">Clear</a>');
            }
            
            var val = $(this).find('input').val();
            if(val.length){
              console.log(val);
              val = parseInt(val) * 1000;
              console.log(val); 
              var date = new Date(val);
              var day = (date.getDate() < 10 ? '0' : '') + date.getDate(); //adding leading 0 if date less than 10 for the required dd format
              var month = (date.getMonth() < 9 ? '0' : '') + (date.getMonth() + 1); //adding leading 0 if month less than 10 for mm format. Used less than 9 because javascriptmonths are 0 based.
              var year = date.getFullYear(); //full year in yyyy format
              console.log(day+'/'+month+'/'+year);
              $(this).find('.labl').html(day+'/'+month+'/'+year);
            }else{
              $(this).find('.labl').html('Choose a date');
            }
          });
          $( ".acf-field.dpick input" ).datepicker({
            autoclose:true,
            format: {
              toDisplay: function (date, format, language) {
                  var d = new Date(date);
                  return d.getTime()/1000;
              },
              toValue: function (date, format, language) {
                  var d = new Date(date);
                  
                  return new Date(d);
              }
            },
            forceParse:false,
            orientation:'bottom'
          }).on('changeDate', function(ev){
            console.log(ev);
              var ts = ev.date.valueOf(); 
              var date = new Date(ts);
              var day = (date.getDate() < 10 ? '0' : '') + date.getDate(); //adding leading 0 if date less than 10 for the required dd format
              var month = (date.getMonth() < 9 ? '0' : '') + (date.getMonth() + 1); //adding leading 0 if month less than 10 for mm format. Used less than 9 because javascriptmonths are 0 based.
              var year = date.getFullYear(); //full year in yyyy format
              $(ev.target).parents('.dpick').find('.labl').html(day+'/'+month+'/'+year);
          });
        };
        setDates();
        $(".acf-input .acf-repeater").on('click a[data-event="add-row"]', function(evt, el){
          setDates();
        });
        $('body').on('click','.dpick .acf-input',function(e){
          if($(e.target).hasClass('clear')){
            $(this).parents('.dpick').find('.labl').html('Choose a date');
            $(this).parents('.dpick').find('input').val('');
            return;
          }
          var tar = $(this).find('.labl').data('for');
          $(tar).datepicker().focus();
        });
        $('body').on('click','.datepicker + i',function(){
          $(this).siblings('.datepicker').focus();
        });
        function registerAgent(){
          var overlay = $('#register-form .loading-overlay');
          var cont = $('#register-form .loading-overlay .cont');
          overlay.fadeIn();
          var formdata = $('#register-form').serialize();
          $.ajax({
            type:"POST",
            url: "/wp-admin/admin-ajax.php",
            action: registerAgent,
            data: formdata,
            success:function(data){
              cont.html(data);
            }
          }); 
          return false;
        }
        $('#register-form').submit(registerAgent);

        function contactForm(){
          var overlay = $('#ajax');
          var cont = $('#ajax.loading-overlay .cont');
          overlay.fadeIn();
          var url = window.location.href;
          var isagg;
          if(url.indexOf("agreement")){
            isagg = 'yes';
          }else {
            isagg = 'no';
          }
          $.ajax({
            type:"POST",
            url: "/wp-admin/admin-ajax.php",
            action: contactForm,
            data:{action:"contactForm",isagg:isagg,security:non},
            success:function(data){
              cont.html(data);
              setupAddress();
              setDates();
            }
          }); 
          return false;
        }
        $('#new-contact').on("click",contactForm);
        $('body').on('click','#another-new-contact',function(){
          $('#newContact').trigger('reset');
          $('#newContact .loading-overlay .cont').html('<i class="fa fa-spinner fa-spin"></i>');
          $('#newContact .loading-overlay').hide();
          $('#opc_button').show();
          $('#opc_dropdown').hide();
          $('#addressfound').hide();
        });
        function companyForm(){
          console.log('gogogo');
          var cont = $('#ajax.loading-overlay .cont');
          var overlay = $('#ajax');
          cont.html('<i class="fa fa-spinner fa-spin"></i>');
          overlay.fadeIn();
          $.ajax({
            type:"POST",
            url: "/wp-admin/admin-ajax.php",
            action: companyForm,
            data:{action:"companyForm",security:non},
            success:function(data){
              cont.html(data);
              setupAddress();
              setDates();
            }
          }); 
          return false;
        }
        $('body').on("click",'#newCompanyForm',companyForm);
        $('body').on('keypress','#opc_input',function(e)
        {
          if(e.keyCode === 13) 
          {
              console.log('you pressed enter');
              $(this).next('#opc_button').trigger('click');
              return false;
          } 
        });
        $('body').on("keyup",'.userSearch',function(){
          var doCos;
          if($('#doCos').length){
            if($('#doCos').val() === 'yes'){
              doCos = 'yes';
            }else{
              doCos = 'no';
            }
          }else{
            doCos = 'no';
          } 
          var s = $(this).val();
          if(s.length > 0){
            var e = $(this);
            var dat = {action:"userSearch",search:s,security:non,doCos:doCos};
            var exc = false;
            if($(this).data('exclude')){
              exc = $(this).data('exclude');
              dat.exclude = exc;
            }
            $.ajax({
              type:"POST",
              url: "/wp-admin/admin-ajax.php",
              data:dat,
              success:function(data){
                if(e.parents('.sidebar').length){
                  $('#pplList').html(data).show();
                  $('.userlist').show();
                } else  {
                  e.siblings('.searchResults').html(data).show();
                  $('.userlist').show();
                }
              }
            }); 
            return false;
          }
          
        });
        $('.openSearch').click(function(){
          $('#globalSearch').fadeToggle();
          $('#search').focus();
        });
        var loadMore=true;
        $('#aggList').scroll(function(){
          if($('.ajaxLoadMore').length === 0){
            return;
          }
          if (checkInView('.ajaxLoadMore','.searchList',true)) {
            if(loadMore){
              loadMore =false;
              var count = $('.searchList .propSummary').length;
              var data = {
                action:"searchAgreements",
                security:non,
                offset:count
              };
              var s = $('#searchAgreements').val();
              if(s.length>0){
                data.search = s;
              } 
              if($('#showingInc').length){
                data.hideComplete = 'yes';
              }
              $.ajax({
                type:"POST", 
                url: "/wp-admin/admin-ajax.php",
                data:data,
                success:function(data){
                  $('#aggList .ajaxLoadMore').replaceWith(data);
                  loadMore = true;
                },
                error:function(data){
                  $('#aggList').append('<div class="alert alert-danger">Uh ohhh, there was a problem.</div>');
                  loadMore = true;
                }
              }); 
            }
          }
        });
        $('#propList').scroll(function(e){
          e.preventDefault();
          if($('.ajaxLoadMore').length === 0){
            return;
          }
          if (checkInView('.ajaxLoadMore','#propList',true)) {
            if(loadMore){
              loadMore =false;
              var count = $('#propList .propSummary').length;
              var data = {
                action:"hasProperties",
                security:non,
                offset:count
              };
              var s = $('#searchProps').val();
              if(s.length>2){
                data.search = s;
              } 
              $.ajax({
                type:"POST", 
                url: "/wp-admin/admin-ajax.php",
                data:data,
                success:function(data){
                  $('#propList .ajaxLoadMore').replaceWith(data);
                  loadMore = true;
                },
                error:function(data){
                  $('#propList').append('<div class="alert alert-danger">Uh ohhh, there was a problem.</div>');
                  loadMore = true;
                }
              }); 
            }
          }
        });
        $('.notifics .dashCard-block').scroll(function(e){
          e.preventDefault();
          
          if($('.notifics .ajaxLoadMore').length === 0){
            return;
          }
          if (checkInView('.notifics .ajaxLoadMore','.notifics .dashCard-block',true)) {
            if(loadMore){
              loadMore =false;
              var count = $('.notifics .notification').length;
              console.log(count);
              var data = {
                action:"ajaxNotifications",
                security:non,
                offset:count
              };
              $.ajax({
                type:"POST", 
                url: "/wp-admin/admin-ajax.php",
                data:data,
                success:function(data){
                  $('.notifics .ajaxLoadMore').replaceWith(data);
                  loadMore = true;
                },
                error:function(data){
                  $('.notifics .dashCard-block').append('<div class="alert alert-danger">Uh ohhh, there was a problem.</div>');
                  loadMore = true;
                }
              }); 
            }
          }
        });
        $('.agentDashRow').on('click','.propSummary',function(){
          var pid = $(this).data('id');
          var url = '/agreement/?pid='+pid;
          window.location.replace(url);
        });
        function updateOfficers(){
          var ids='';
          var sel = '.existingOfficers > div';
          if($(sel).length === 0){
            $('#company_officers').val(''); 
            return;
          }
          if($(sel).length === 1){
            ids = $(sel).data('id');
            $('#company_officers').val(ids); 
            return;
          }
          $(sel).each(function(){
            ids += $(this).data('id')+','; 
            $('#company_officers').val(ids); 
          });
          console.log(sel);
          return;
        }
        $('body').on('click','.fw-company_officers li',function(){
          var id = $(this).data('id');
          var name = $(this).data('name');
          var email = $(this).data('email');
          var sel = '.existingOfficers div[data-id='+id+']';
          if($(sel).length){
            $('.uListErrors').html('This user has already been added').fadeIn();
            return;
          }
          var string = '<div class="officer" data-id="'+id+'"><div><h3>'+name+'</h3><span>'+email+'</span></div><a class="remove fa fa-close"></a></div>';
          $('.existingOfficers').append(string);
          updateOfficers();
          $('.searchResults').hide().html('');
          $('#newCompany .userSearch').val('').focus();
        });
        $('body').on('click','.existingOfficers .remove',function(){
          $(this).parents('.officer').remove();
          updateOfficers();
        });

        function resetForm(form) {
          $(form).find('input:text, input:password, input:file, select, textarea').val('');
          $(form).find('input:radio, input:checkbox')
               .removeAttr('checked').removeAttr('selected');
        }
        $('body').on('change','input[type=radio]',function(){
          var t = $(this);
          t.parents('.acf-radio-list').find('input').each(function(){
            $(this).prop('checked', false);
          });
          t.parents('.acf-radio-list').find('.selected').removeClass('selected');
          t.parents('label').addClass('selected');
          t.prop('checked',true);
          return true;
        });
        //$(this).parents('.acf-radio-list').find('.selected').removeClass('selected');
        //$(this).parents('label').addClass('selected');
        //
        
        function newCompany(){
          var overlay = $('#ajax');
          var cont = $('#newCompany .loading-overlay .cont');
          overlay.fadeIn();
          var formdata = $('#newCompany').serialize();
          console.log(formdata);
          $.ajax({
            type:"POST",
            url: "/wp-admin/admin-ajax.php?action=newCompany",
            data: formdata,
            success:function(data){
              if(data === 'true'){
                qMessage('Company updated','success');
                //resetForm('#newCompany');
                $('.existingOfficers').html('');
                $('#company_officers').val('');
                overlay.hide();
              }else{
                cont.html('<h2>Fail</h2><p>'+data+'</p>');
              }
            }
          }); 
          return false;
        }
        $("body").on("click",'#newCompany input[type=submit]',function(e){
          e.preventDefault();
          $("#newCompany").submit();
        });
        $('body').on('submit','#newCompany',function(e){
          e.preventDefault();
          newCompany();
          return false;
        });


        $('body').on('focus','.userSearch',function(){
          $('#pplList').show();
          $('.userlist').show();
        }); 
        $('input[name=contract_type]').change(function(){
          if($('#normal').is(':checked')){
            $('.fw-included_areas').hide();
            $('.fw-shared_type').hide();
            $('.bedsitFields').hide();
          }else{
            $('.fw-included_areas').show();
            $('.fw-shared_type').show();
            $('.bedsitFields').show();
          }
        });
        if($('#normal').length){
          if($('#normal').is(':checked')){
            $('.fw-included_areas').hide();
            $('.fw-shared_type').hide();
          }else{
            $('.fw-included_areas').show();
            $('.fw-shared_type').show();
          }
        }
        $('body').click(function(){
          $('.quicksuccess').fadeOut();
        });
        $(document).on("click",".properties .searchResults li",function(){
          var id;
          if($(this).hasClass('company')){
            id='company'+$(this).data("id");
          }else{
            id=$(this).data("id");
          }
          var nm = $(this).data("name");
          var tar = $(this).parents('.searchResults');
          $('#acf-field_5de511c9a76c3').val(id);
          tar.siblings('.userSearch').val(nm);
          tar.siblings('.userSearch').blur();
          tar.fadeOut();
        });
        $('.searchResults').on('click','.close',function(e){
          $('.searchResults').hide();
        });
        $(document).on("click",".clearform",function(){
          var target = $(this).data('target');
          $(target).trigger('reset');
          $(this).parent('.card-header').html('Add a new property <a class=\"clearform hidden\" data-target=\"#properties\">Clear</a>');
          $(target).find('input[type=hidden].identifier').val('NEW');
          $(target).find('.postcode-finder').show();
          $(target).find('.entermanually').show();
          $(target).find('#addressfound').hide();
        });
        $(document).on("change","input, select, textarea",function(){
          $(this).parents('.card').find('.clearform').removeClass('hidden');
        });
        $('.tabnav a').on("click",function(){
          if(!$(this).hasClass('active')){
            $('.page-space').scrollTop(0);
            $('.tabnav a.active').removeClass('active');
            $(this).addClass('active');
            var target= $(this).data('target');
            window.location.hash = target;
            $('.tab-content.active').hide().removeClass('active');
            $(target).fadeIn().addClass('active'); 
            $('.newVis .scroll').attr('data-act',target);
            console.log('oner');
            if($(window).width() < 768){
              $('.tabnav').fadeOut();
              $('.nav-secondary .hamburger').addClass('active');
            }
          }
        });
        if($('.tabnav').length){
          var hash = window.location.hash;
          if(hash.length < 1 || hash === "#undefined"){
            $(window).load(function(){
              var el = $('.tabnav li:first-of-type a');
              el.addClass('active');
              var tar = el.data('target');
              $(tar).fadeIn().addClass('active');
            });
          } else {
            $('.tab-content.active').removeClass('active').hide();
            $(hash).show().addClass('active');
            $('.newVis .scroll').attr('data-act',hash);
          }
        }
        $('#profMenu a').click(function(){
          $('.tab-content.active').hide().removeClass('active');
          var target= $(this).attr('href');
          $(target).addClass('active').fadeIn();
        });
        $('.banner .hamburger').click(function(){
          $(this).toggleClass('is-active');
          $('.nav-primary').fadeToggle();
          $('.banner .right').fadeToggle();
        });
        $('.nav-secondary .hamburger').click(function(){
          $(this).toggleClass('active');
          $('.tabnav').fadeToggle();
        });
      } 
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

jQuery(document).ready(function() {
          
          function checkStrength(password){
          var strength = 0;
          if (password.length < 6) {
          jQuery('#passresult').removeClass();
          jQuery('#passresult').addClass('short');
          return 'Too short';
          }
          if (password.length > 7){
            strength += 1;
          }
          if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)){
              strength += 1;
          }
          if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/)){
            strength += 1;
          }
          if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)){
            strength += 1;
          }
          if (password.match(/(.*[!,%,&,@,#,$,^,*,?,_,~].*[!,%,&,@,#,$,^,*,?,_,~])/)){
            strength += 1;
          }
          // If value is less than 2
          if (strength < 2) {
            jQuery('#passresult').removeClass();
            jQuery('#passresult').addClass('weak');
            return "Too Weak";
          } else if (strength === 2) {
            jQuery('#passresult').removeClass();
            jQuery('#passresult').addClass('good');
            return "Medium";
          } else {
            jQuery('#passresult').removeClass();
            jQuery('#passresult').addClass('strong');
            return "Strong";
          }
          }
          jQuery('#enterpass').on("keyup",function() {
            jQuery('#passresult').html(checkStrength(jQuery('#enterpass').val()));
          }); 
}); 